import React from 'react';
import { useStyles } from '../styles';
import Slider from "react-slick";
export default function Categories(props) {
  const styles = useStyles();
  console.log(props.pgSets);
  var settings = {
    dots:false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    swipeToSlide: true,
    vertical:!props.pgSets.isVerticalScreen,
    verticalSwiping: !props.pgSets.isVerticalScreen
  
  };
  let dateNow= new Date().getTime();
  return (
     <div style={{width:props.pgSets.isVerticalScreen?"100%":"150px",borderRight:'1px solid #ccc'}} key={dateNow}>
     <Slider {...settings} >
          {props.categories &&
            props.categories.filter(cat=> cat.isOrderableAlone || !cat.isAddOn).map((cat) => (
              <div
                className={styles.categoryItem}
                style={{borderColor:props.selectedCat.id == cat.id?props.pgSets.themeColor:"#000"}}
                onClick={() =>
                  props.categoryClickHandler(cat)
                }
              >
                <div className={styles.imageHolder} >
                  <img
                    className={styles.image +" "+ 'cat-img'}
                    alt={cat.name}
                    onError={props.imageOnErrorHandler}
                    src={`${props.baseURL}/` + cat.image}
                  />
                </div>
                <div className='cat-name'>{cat.name}</div>
              </div>
            ))}
    
      </Slider>
      </div>
  );
}
